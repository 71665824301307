import AlephBet from "alephbet"
import {storage_adapter, lamed_adapter} from "./adapters"
import {not_logged_in, on_article_page} from "./helpers"
import {embedded_quiz_clicked, registered_successfully} from "./goals"

export default () => {
  const sample_quiz_on_articles = new AlephBet.Experiment({
    name: "202409 Sample quiz on articles",
    tracking_adapter: lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () => not_logged_in() && on_article_page(),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Sample-quiz": {
        activate: (experiment) => {
          if (!experiment.options.trigger()) return

          window.gon.sample_quiz = true
        }
      }
    }
  })

  sample_quiz_on_articles.add_goals([
    embedded_quiz_clicked,
    registered_successfully
  ])
}
