import AlephBet from "alephbet"
import {
  storage_adapter,
  super_properties_lamed_adapter
} from "./adapters"
import {experiment_tracking, get_user_id, logged_in_user} from "./helpers"
import {pricing_page_visited, checkout_page_visited} from "./goals"

export default () => {
  const new_trainer = new AlephBet.Experiment({
    name: "202410 New trainer (v2)",
    tracking_adapter: super_properties_lamed_adapter,
    storage_adapter: storage_adapter,
    user_id: get_user_id(),
    trigger: () => logged_in_user() &&
      !window.gon.new_trainer_ab_test_disabled &&
      // Mon, 07 Oct 2024 16:00:00 +0000
      logged_in_user().confirmed_at > 1728316800,
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-New-trainer": {
        activate: experiment => {
          if (!experiment.options.trigger()) return

          // makes sure that we keep trying to activate the experiment
          // in case it was blocked when the experiment started
          experiment_tracking(experiment.name, "2-New-trainer")
        }
      }
    }
  })

  new_trainer.add_goals([
    pricing_page_visited,
    checkout_page_visited
  ])
}
