import AlephBet from "alephbet"
import $ from "cash-dom"
import {storage_adapter, lamed_adapter} from "./adapters"
import {not_logged_in, on_homepage} from "./helpers"
import {registered_successfully} from "./goals"

export default () => {
  const update_the_homepage = new AlephBet.Experiment({
    name: "202410 Update the homepage to improve conversion",
    tracking_adapter: lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () => not_logged_in() && on_homepage(),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Update-homepage": {
        activate: (experiment) => {
          if (!experiment.options.trigger()) return
          // Remove any uneeded elements from the original
          $(".ab_test-232--original").remove()

          // Activate the variant
          $("body").addClass("ab_test-232")
          $(".ab_test-232--variant").addClass("active")

          // Re-order the homepage sections
          $(".topics").insertBefore(".benefits")
          $(".experts").insertAfter(".breaking-cta")
          $("<div class='l-container new'></div>").insertAfter(".experts")
          $("<div class='benefits new'></div>").insertBefore(".recommendations")
          $(".callout").clone().appendTo(".l-container.new")
          $(".benefits .benefits__row:last-of-type").appendTo(".benefits.new")
        }
      }
    }
  })

  update_the_homepage.add_goals([
    registered_successfully
  ])
}
